import { ChainId } from "@gtoken/sdk"

export const GTOKEN_MAP: { [chainId in ChainId]?: Map<string, string> } = {
  [ChainId.BSCTEST]: new Map([
    ['0x46B98eCfe9E90e1B1c03cbFFcbf72A5174b32C45', '0x0b8E3E331dAa0Cd134Ab1B5Bc6C7400486193BA8'],
    ['0xF420614AA5a7fc21038b3AF7C154440F96379CC8', '0x23511f6465a062818b751c63C1c803a52dFaC659'],
    ['0x4C1Debf613b58519D15a0177dCDB5166186DE4DE', '0xd9CE7C3e61e26feEdd70Ad4d1849938F39620939'],
    ['0x4fB312374D8973BD14d46Ea53156d42024F4C012', '0xABfB0ca2125590De5A2FA39E40dc272A2A8aeDB5'],
    ['0x59988681a1E89bA6cA3454Ac7a8D0426A0479Bf9', '0x02476d0a6a2393fe71E27Ca97BB25d675089963e'],
  ]),
  [ChainId.BSCMAIN]: new Map([
    ['0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', '0x7c3ABe24Aa308ffdd30ABb902B6c24a501baB6Ca'],
    ['0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', '0xA9405129AC7c2d9829BF73a64669F9de48426689'],
    ['0x55d398326f99059fF775485246999027B3197955', '0x5Cdc07740C4c347B7bfc1Ef5c16d671534C03A8d'],
    ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', '0xdCEA48E4924156C79711011fc6286A6607c37040'],
    ['0xA2120b9e674d3fC3875f415A7DF52e382F141225', '0xAedf4228776C158FB2A2D1fc77a883d5C99186A2'],
    ['0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', '0x0B1a87021ec75fBaE919b1e86b2B1335FFC8F4d3'],
    ['0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402', '0x4432a6DcfAEAB227673B43C30c6fEf40eaBD5D30'],
    ['0x2170Ed0880ac9A755fd29B2688956BD959F933F8', '0x18eb8AF587dcd7E4F575040F6D800a6B5Cef6CAf']
  ]),
  [ChainId.POLYGON]: new Map([
    ['0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', '0xF6a8aD553b265405526030c2102fda2bDcdDC177'],
    ['0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', '0x38AcA0510B9E48c77818B73F7b14957043607587'],
    ['0xc2132D05D31c914a87C6611C10748AEb04B58e8F', '0xDD85148fdBc13A82fa6bF7C42Cc5C422954BF276'],
    ['0x831753DD7087CaC61aB5644b308642cc1c33Dc13', '0x91Fa0d861E9F2f73d38CdB433053e9343559E950'],
    ['0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', '0x4f03c5B889930A8EbA94588cb304c9c2e6A3456F'],
  ]),
  [ChainId.MAINNET]: new Map([
    ['0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', '0x38AcA0510B9E48c77818B73F7b14957043607587'],
    ['0xdAC17F958D2ee523a2206206994597C13D831ec7', '0xDD85148fdBc13A82fa6bF7C42Cc5C422954BF276'],
    ['0xA2120b9e674d3fC3875f415A7DF52e382F141225', '0x91Fa0d861E9F2f73d38CdB433053e9343559E950']
  ]),
}