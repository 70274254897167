// import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { JSBI, Percent, Router, SwapParameters, Trade, TradeType, Pair, Token, ChainId } from '@gtoken/sdk'
import { useMemo } from 'react'
import { BIPS_BASE, GTOKEN_CONTROLLER_MAP, INITIAL_ALLOWED_SLIPPAGE } from '../constants'
import { getTradeVersion, useV1TradeExchangeAddress } from '../data/V1'
import { useSimpleTransactionAdder } from '../state/transactions/hooks'
// import { calculateGasMargin, getRouterContract, isAddress, shortenAddress } from '../utils'
// import { getRouterContract, shortenAddress } from '../utils'
import { getRouterContract } from '../utils'
// import isZero from '../utils/isZero'
import v1SwapArguments from '../utils/v1SwapArguments'
import { useActiveWeb3React } from './index'
import { useV1ExchangeContract } from './useContract'
import useTransactionDeadline from './useTransactionDeadline'
import useENS from './useENS'
import { Version } from './useToggledVersion'
// import { isAddress, splitSignature } from 'ethers/lib/utils'
import { splitSignature } from 'ethers/lib/utils'
import { RELAYER_ENDPOINT_MAP } from '../constants/relayer'
import CONTROLLER_ABI from '../constants/abis/Controller.json'
import { BigNumber } from 'ethers'
import { getContract } from '../utils'
import { calculateFeeOnToken, SWAP_GAS_LIMIT, HOP_ADDITIONAL_GAS } from '../constants/price/price'
import { findOriginalTokenAddress, useIsExpertMode, useUserSwapGasLimit } from '../state/user/hooks'
import { Interface } from '@ethersproject/abi'
import {BigNumber as JSBigNumber} from 'bignumber.js'
// import { Log } from '@ethersproject/abstract-provider/src.ts/index'
// import { GTOKEN_MAP } from '../constants/gtokens/gtokens'
// import useTransactionSwapGasLimit from './useTransactionSwapGasLimit'

export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID
}

interface SwapCall {
  contract: Contract
  parameters: SwapParameters
}

// interface SuccessfulCall {
//   call: SwapCall
//   gasEstimate: BigNumber
// }

// interface FailedCall {
//   call: SwapCall
//   error: Error
// }

// type EstimatedSwapCall = SuccessfulCall | FailedCall

/**
 * Returns the swap calls that can be used to make the trade
 * @param trade trade to execute
 * @param allowedSlippage user allowed slippage
 * @param recipientAddressOrName
 */
function useSwapCallArguments(
  trade: Trade | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
  recipientAddressOrName: string | null // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
): SwapCall[] {
  const { account, chainId, library } = useActiveWeb3React()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress
  const deadline = useTransactionDeadline()

  const v1Exchange = useV1ExchangeContract(useV1TradeExchangeAddress(trade), true)

  return useMemo(() => {
    const tradeVersion = getTradeVersion(trade)
    if (!trade || !recipient || !library || !account || !tradeVersion || !chainId || !deadline) return []

    const contract: Contract | null =
      tradeVersion === Version.v2 ? getRouterContract(chainId, library, account) : v1Exchange
    if (!contract) {
      return []
    }

    const swapMethods = []

    switch (tradeVersion) {
      case Version.v2:
        swapMethods.push(
          Router.swapCallParameters(trade, {
            feeOnTransfer: false,
            allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
            recipient,
            deadline: deadline.toNumber()
          })
        )

        if (trade.tradeType === TradeType.EXACT_INPUT) {
          swapMethods.push(
            Router.swapCallParameters(trade, {
              feeOnTransfer: true,
              allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
              recipient,
              deadline: deadline.toNumber()
            })
          )
        }
        break
      case Version.v1:
        swapMethods.push(
          v1SwapArguments(trade, {
            allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
            recipient,
            deadline: deadline.toNumber()
          })
        )
        break
    }
    return swapMethods.map(parameters => ({ parameters, contract }))
  }, [account, allowedSlippage, chainId, deadline, library, recipient, trade, v1Exchange])
}

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback(
  trade: Trade | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
  recipientAddressOrName: string | null // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
): { state: SwapCallbackState; callback: null | (() => Promise<{txHash: string, preventedLoss: string | undefined}>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()

  const swapCalls = useSwapCallArguments(trade, allowedSlippage, recipientAddressOrName)

  const addTransaction = useSimpleTransactionAdder()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  const isExpertMode = useIsExpertMode()
  const [userSwapGasLimit, setUserSwapGasLimit] = useUserSwapGasLimit()
  let swapGasLimit = userSwapGasLimit || SWAP_GAS_LIMIT
  // Revert swap gas limit to its default value if user toggles the
  // expert mode back to off
  if (!isExpertMode && swapGasLimit !== SWAP_GAS_LIMIT) {
    swapGasLimit = SWAP_GAS_LIMIT
    setUserSwapGasLimit(swapGasLimit)
  }

  return useMemo(() => {
    if (!trade || !library || !account || !chainId) {
      return { state: SwapCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }
    if (!recipient) {
      if (recipientAddressOrName !== null) {
        return { state: SwapCallbackState.INVALID, callback: null, error: 'Invalid recipient' }
      } else {
        return { state: SwapCallbackState.LOADING, callback: null, error: null }
      }
    }

    // const tradeVersion = getTradeVersion(trade)

    return {
      state: SwapCallbackState.VALID,
      // return a transaction hash or return the error
      callback: async (): Promise<{txHash: string, preventedLoss: string | undefined}> => {
        console.table(swapCalls)

        if (!swapCalls || swapCalls.length === 0) {
          throw new Error('Cannot create swap call')
        }

        const {
          parameters: { methodName, args }
        } = swapCalls[0]

        console.log(`Swapping: methodName is ${methodName}`)
        const sender = await library.getSigner().getAddress()

        // https: github.com/Uniswap/uniswap-sdk/blob/a88048e9c4198a5bdaea00883ca00c8c8e582605/src/router.ts#L114
        const [amount0, amount1, path, user, deadline] = args
        if (user !== sender) {
          throw new Error("Wrong sender")
        }

        const controller = getContract(GTOKEN_CONTROLLER_MAP[chainId], CONTROLLER_ABI, library, account)
        for (var i = 0; i < path.length - 1; i++) {
          const tokenA = new Token(chainId, path[i], trade.route.path[i].decimals)
          const tokenB = new Token(chainId, path[i + 1], trade.route.path[i + 1].decimals)
          const pairAddress = Pair.getAddress(tokenA, tokenB)
          var isTrustedPair: boolean = false
          if (chainId === ChainId.BSCMAIN || chainId === ChainId.BSCTEST) {
            isTrustedPair = await controller.isTrustedPancakeV2Pair(pairAddress)
          } else {
            isTrustedPair = await controller.isTrustedPair(pairAddress)
          }
          if (!isTrustedPair) {
            throw new Error("Not trusted pair!")
          }
        }
        
        const nonce: BigNumber = await controller.nonces(sender)
        // (`deadline ${deadline}`)

        // if (path.length !== 2) {
        //   throw new Error('Does not support multi-hop swap yet')
        // }

        const gasPrice = await library?.getGasPrice()
        const gasLimit = swapGasLimit + (path.length - 2) * HOP_ADDITIONAL_GAS
        const feeOnTokenA = await calculateFeeOnToken(chainId, findOriginalTokenAddress(chainId!,path[0]), trade.inputAmount.currency.decimals, gasPrice === undefined ? undefined: gasPrice.mul(gasLimit))

        if (methodName !== 'swapExactTokensForTokens') {
          if (methodName === 'swapTokensForExactTokens') {
            throw new Error('Does not support setting output amount')
          } else {
            throw new Error('Can only between ERC-20 tokens')
          }
        }

        const EIP712Domain = [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' }
        ]

        const Swap = [
          { name: 'amount0', type: 'uint256' },
          { name: 'amount1', type: 'uint256' },
          { name: 'path', type: 'address[]' },
          { name: 'user', type: 'address' },
          { name: "nonce", type: 'uint256' },
          { name: 'deadline', type: 'uint256' },
          { name: 'feeAmount', type: 'uint256' },
          { name: 'feeToken', type: 'address'}
        ]

        const domain = {
          name: 'GToken',
          version: '1',
          chainId: BigNumber.from(chainId).toHexString(),
          verifyingContract: GTOKEN_CONTROLLER_MAP[chainId]
        }

        const message = {
          amount0,
          amount1,
          path: path,
          user: sender,
          nonce: nonce.toString(),
          deadline,
          feeAmount: BigNumber.from(feeOnTokenA.toFixed(0)).toHexString(),
          feeToken: path[0]
        }

        const EIP712Msg = {
          types: {
            EIP712Domain,
            Swap
          },
          domain,
          primaryType: 'Swap',
          message
        }

        const data = JSON.stringify(EIP712Msg)

        const signature = await library.send('eth_signTypedData_v4', [sender, data])
        const { v, r, s } = splitSignature(signature)

        const params = [chainId, EIP712Msg, v.toString(), r, s]

        const jsonrpcRequest = {
          jsonrpc: '2.0',
          method: '/v0/' + methodName,
          id: 1,
          params
        }

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(jsonrpcRequest)
        }
        console.log(jsonrpcRequest)
        const environment = process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : "staging"
        const response = await fetch(RELAYER_ENDPOINT_MAP[environment][chainId]!, requestOptions)

        // const inputSymbol = trade.inputAmount.currency.symbol
        // const outputSymbol = trade.outputAmount.currency.symbol
        // const inputAmount = trade.inputAmount.toSignificant(3)
        // const outputAmount = trade.outputAmount.toSignificant(3)

        // const base = `Swap ${inputAmount} ${inputSymbol} for ${outputAmount} ${outputSymbol}`
        // const withRecipient =
        //   recipient === account
        //     ? base
        //     : `${base} to ${
        //         recipientAddressOrName && isAddress(recipientAddressOrName)
        //           ? shortenAddress(recipientAddressOrName)
        //           : recipientAddressOrName
        //       }`

        // const withVersion =
        //   tradeVersion === Version.v2 ? withRecipient : `${withRecipient} on ${(tradeVersion as any).toUpperCase()}`
        const { result } = await response.json()
    
    
        const originalInputTokenSymbol = trade.inputAmount.currency.symbol?.startsWith('g') ? 
          trade.inputAmount.currency.symbol.substring(1) : trade.inputAmount.currency.symbol
        const originalOutputTokenSymbol = trade.outputAmount.currency.symbol?.startsWith('g') ? 
          trade.outputAmount.currency.symbol.substring(1) : trade.outputAmount.currency.symbol

        if (result.success === true) {
          addTransaction(result.txnHash as string, {
            summary:
              'Swap ' +
              trade.inputAmount.toSignificant(3) +
              ' ' +
              originalInputTokenSymbol +
              ' to ' +
              originalOutputTokenSymbol
          })
          var receipt = null
          while (receipt === null) {
            receipt = await library.getTransactionReceipt(result.txnHash)
          }
          const transactionLogs = receipt.logs
          var savedLoss: JSBigNumber | undefined = undefined
          var lastUsedLogIndex: number = -1
          for (let log of transactionLogs) {
            //if this trade is a multihop trade, we should use the last SWAP event data
            if (log.topics[0] === '0xd78ad95fa46c994b6551d0da85fc275fe613ce37657fb8d5e3d130840159d822' && log.logIndex > lastUsedLogIndex) {
              const iface = new Interface(["event Swap(address indexed sender,uint amount0In,uint amount1In,uint amount0Out,uint amount1Out,address indexed to)"])
              const logDescription = iface.parseLog(log)
              const amount1Out: JSBigNumber = new JSBigNumber(logDescription.args.amount1Out.toString())
              const amount0Out: JSBigNumber = new JSBigNumber(logDescription.args.amount0Out.toString())
              const amountOut = amount1Out.eq(0) ? amount0Out : amount1Out
              const minAmountOut: JSBigNumber = new JSBigNumber(amount1 as string, 16)
              savedLoss = amountOut.minus(minAmountOut)
              lastUsedLogIndex = log.logIndex
            }
          }
          var preventedLoss: string | undefined = undefined
          if (savedLoss !== undefined) {
            preventedLoss = savedLoss.div(new JSBigNumber(10).pow(trade.outputAmount.currency.decimals)).toPrecision(6) + " " + originalOutputTokenSymbol
          }
          return { txHash: result.txnHash, preventedLoss: preventedLoss}
        } else {
          throw new Error(result.errorMessage)
        }
      },
      error: null
    }
  }, [trade, library, account, chainId, recipient, recipientAddressOrName, swapCalls, addTransaction, swapGasLimit])
  // }, [trade, library, account, chainId, recipient, recipientAddressOrName, swapCalls, addTransaction])
}
