import { ChainId } from '@gtoken/sdk'

export const RELAYER_ENDPOINT_MAP: { [environment: string]: { [chainId in ChainId]?: string } } = {
  production: {
    [ChainId.BSCTEST]: '',
    [ChainId.BSCMAIN]: 'https://conveyor-prod-bsc.ata.network',
    [ChainId.POLYGON]: 'https://conveyor-prod-matic.ata.network',
    [ChainId.MAINNET]: 'https://conveyor-prod-eth.ata.network'
  },
  staging: {
    [ChainId.BSCTEST]: 'https://gtoken-geode-staging.ata.network',
    [ChainId.BSCMAIN]: 'https://gtoken-geode-staging.ata.network',
    [ChainId.POLYGON]: 'https://gtoken-geode-staging.ata.network',
    [ChainId.MAINNET]: 'https://gtoken-geode-staging.ata.network'
  }
}
