// import { BigNumber } from '@ethersproject/bignumber'
// import { TransactionResponse } from '@ethersproject/providers'
// import { currencyEquals, ETHER, TokenAmount, WETH, Pair, Token, ChainId } from '@gtoken/sdk'
import { currencyEquals, ETHER, TokenAmount, WETH } from '@gtoken/sdk'
import React, { useCallback, useContext, useState } from 'react'
import { Plus } from 'react-feather'
// import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { /* ButtonConfirmed, */ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { BlueCard, LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import TransactionConfirmationModal, { ConfirmationModalContent, TransactionErrorContent } from '../../components/TransactionConfirmationModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween, RowFlat } from '../../components/Row'

// import { ROUTER_ADDRESS } from '../../constants'
import { PairState } from '../../data/Reserves'
import { useActiveWeb3React } from '../../hooks'
// import { useCurrency } from '../../hooks/Tokens'
// import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/mint/actions'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../state/mint/hooks'

import { useSimpleTransactionAdder } from '../../state/transactions/hooks'
import { useIsExpertMode, useUserSlippageTolerance, useUserAddLiquidityGasLimit } from '../../state/user/hooks'
import { TYPE } from '../../theme'
// import { calculateGasMargin, calculateSlippageAmount, getRouterContract } from '../../utils'
import { calculateSlippageAmount, getContract } from '../../utils'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import AppBody from '../AppBody'
// import { Dots } from '../Pool/styleds'
import { Wrapper } from '../Pool/styleds'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'
// import { currencyId } from '../../utils/currencyId'
import { PoolPriceBar } from './PoolPriceBar'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { GTOKEN_CONTROLLER_MAP } from '../../constants'
import { splitSignature } from 'ethers/lib/utils'
import { RELAYER_ENDPOINT_MAP } from '../../constants/relayer'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { GTOKEN_MAP } from '../../constants/gtokens/gtokens'
import { calculateFeeOnToken, ADD_LIQUIDITY_GAS_LIMIT, CREATE_PAIR_GAS_LIMIT } from '../../constants/price/price'
import CONTROLLER_ABI from '../../constants/abis/Controller.json'
import { BigNumber } from 'ethers'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { GasFeeBar } from './GasFeeBar'
import { BigNumber as JSBigNumber } from 'bignumber.js'
import { useAddliquidityActionHandlers } from '../../state/AddLiquidity/hooks'
// import { TransactionReceipt } from '@ethersproject/abstract-provider/lib/index'

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB }
  },
  history
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { account, chainId, library } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  // const currencyA = useCurrency(currencyIdA)
  // const currencyB = useCurrency(currencyIdB)

  let weth
      if (chainId && WETH.hasOwnProperty(chainId)) {
      weth = (WETH as any)[chainId]
    } else {
      weth = undefined
    }

  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

  const expertMode = useIsExpertMode()

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    parsedOriginalAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error
  } = useDerivedMintInfo()

  const currencyA = currencies[Field.CURRENCY_A]
  const currencyB = currencies[Field.CURRENCY_B]

  const oneCurrencyIsWETH = Boolean(
    chainId && weth &&
      ((currencyA && currencyEquals(currencyA, weth)) ||
        (currencyB && currencyEquals(currencyB, weth)))
  )

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field])
      }
    },
    {}
  )

  // const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
  //   (accumulator, field) => {
  //     return {
  //       ...accumulator,
  //       [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0')
  //     }
  //   },
  //   {}
  // )

  // check whether the user has approved the router on the tokens
  const tokenAAddress = currencies[Field.CURRENCY_A] ? (currencies[Field.CURRENCY_A] as WrappedTokenInfo).tokenInfo.address : undefined
  const tokenBAddress = currencies[Field.CURRENCY_B] ? (currencies[Field.CURRENCY_B] as WrappedTokenInfo).tokenInfo.address : undefined
  const [approvalA, approveACallback] = useApproveCallback(parsedOriginalAmounts[Field.CURRENCY_A], tokenAAddress ? GTOKEN_MAP[chainId!]!.get(tokenAAddress) : undefined)
  const [approvalB, approveBCallback] = useApproveCallback(parsedOriginalAmounts[Field.CURRENCY_B], tokenBAddress ? GTOKEN_MAP[chainId!]!.get(tokenBAddress) : undefined)

  const addTransaction = useSimpleTransactionAdder()

  const [addLiquidityGasLimit] = useUserAddLiquidityGasLimit()

  async function onAdd() {
    if (!chainId || !library || !account) return
    if (!currencyA || !currencyB) return

    const tokenA = wrappedCurrency(currencyA, chainId)?.address ?? ''
    const tokenB = wrappedCurrency(currencyB, chainId)?.address ?? ''
    // const router = getRouterContract(chainId, library, account)
    const controller = getContract(GTOKEN_CONTROLLER_MAP[chainId], CONTROLLER_ABI, library, account)

    if (GTOKEN_MAP[chainId!]!.get(tokenA) === undefined || GTOKEN_MAP[chainId!]!.get(tokenB) === undefined) {
      setErrorMessage("Not supported token pair")
      return
    }

    // const tokenAIns = new Token(chainId, GTOKEN_MAP[chainId!]!.get(tokenA)!, currencyA.decimals)
    // const tokenBIns = new Token(chainId, GTOKEN_MAP[chainId!]!.get(tokenB)!, currencyB.decimals)
    // const pairAddress = Pair.getAddress(tokenAIns, tokenBIns)
    // var isTrustedPair: boolean = false
    // if (chainId === ChainId.BSCMAIN || chainId === ChainId.BSCTEST) {
    //   isTrustedPair = await controller.isTrustedPancakeV2Pair(pairAddress)
    // } else {
    //   isTrustedPair = await controller.isTrustedPair(pairAddress)
    // }
    // if (!isTrustedPair) {
    //   setErrorMessage("Not trusted pair!")
    //   return
    // }

    const nonce: BigNumber = await controller.nonces(account)
    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
      return
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0]
    }

    if (currencyA === ETHER || currencyB === ETHER) {
      setErrorMessage('Only GToken is supported')
      return
    }

    const EIP712Domain = [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' }
    ]

    const AddLiquidity = [
      { name: 'tokenA', type: 'address' },
      { name: 'tokenB', type: 'address' },
      { name: 'amountADesired', type: 'uint256' },
      { name: 'amountBDesired', type: 'uint256' },
      { name: 'amountAMin', type: 'uint256' },
      { name: 'amountBMin', type: 'uint256' },
      { name: 'user', type: 'address' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' },
      { name: 'feeAmount', type: 'uint256' },
      { name: 'feeToken', type: 'address'}
    ]

    const domain = {
      name: 'GToken',
      version: '1',
      chainId: BigNumber.from(chainId).toHexString(),
      verifyingContract: GTOKEN_CONTROLLER_MAP[chainId]
    }

    const amountADesired = parsedAmountA.raw.toString()
    const amountBDesired = parsedAmountB.raw.toString()
    const amountAMin = amountsMin[Field.CURRENCY_A].toString()
    const amountBMin = amountsMin[Field.CURRENCY_B].toString()

    const gasPrice = await library?.getGasPrice()
    // When PairState exists, put addLiquidityGasLimit from state if its defined, else revert to constant
    const gasLimit =
      pairState === PairState.NOT_EXISTS
        ? CREATE_PAIR_GAS_LIMIT
        : typeof addLiquidityGasLimit === 'number'
        ? addLiquidityGasLimit
        : ADD_LIQUIDITY_GAS_LIMIT
    const feeOnTokenA = await calculateFeeOnToken(chainId, tokenAAddress, currencyA.decimals, gasPrice === undefined ? undefined: gasPrice.mul(gasLimit))
    
    const message = {
      tokenA: GTOKEN_MAP[chainId!]!.get(tokenA),
      tokenB: GTOKEN_MAP[chainId!]!.get(tokenB),
      amountADesired,
      amountBDesired,
      amountAMin,
      amountBMin,
      user: account,
      nonce: nonce.toString(),
      deadline: deadline.toHexString(),
      feeAmount: BigNumber.from(feeOnTokenA.toFixed(0)).toHexString(),
      feeToken: GTOKEN_MAP[chainId!]!.get(tokenA)
    }

    const EIP712Msg = {
      types: {
        EIP712Domain,
        AddLiquidity
      },
      domain,
      primaryType: 'AddLiquidity',
      message
    }

    const data = JSON.stringify(EIP712Msg)
    setAttemptingTxn(true)
    const signature = await library.send('eth_signTypedData_v4', [account, data])
    const { v, r, s } = splitSignature(signature)

    const params = [chainId, EIP712Msg, v.toString(), r, s]

    const jsonrpcRequest = {
      jsonrpc: '2.0',
      method: '/v0/addLiquidity',
      id: 1,
      params
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jsonrpcRequest)
    }
    console.log(jsonrpcRequest)
    const environment = process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : "staging"
    const jsonrpcResponse = await fetch(RELAYER_ENDPOINT_MAP[environment][chainId]!, requestOptions)

    const { result: response } = await jsonrpcResponse.json()

    setAttemptingTxn(false)

    if (response.success === true) {
      addTransaction(response.txnHash as string, {
        summary:
          'Add ' +
          parsedAmounts[Field.CURRENCY_A]?.toSignificant(3) +
          ' ' +
          currencies[Field.CURRENCY_A]?.symbol +
          ' and ' +
          parsedAmounts[Field.CURRENCY_B]?.toSignificant(3) +
          ' ' +
          currencies[Field.CURRENCY_B]?.symbol
      })

      setTxHash(response.txnHash)
    } else {
      setErrorMessage(response.errorMessage)
      return
    }
  }

  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn gap="20px">
        <LightCard mt="20px" borderRadius="20px">
          <RowFlat>
            <Text fontSize="48px" fontWeight={500} lineHeight="42px" marginRight={10} style={{wordBreak: 'break-all'}}>
              {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol}
            </Text>
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={30}
            />
          </RowFlat>
        </LightCard>
      </AutoColumn>
    ) : (
      <AutoColumn gap="20px">
        <RowFlat style={{ marginTop: '20px' }}>
          <Text fontSize="48px" fontWeight={500} lineHeight="42px" marginRight={10} style={{wordBreak: 'break-all'}}>
            {liquidityMinted?.toSignificant(6)}
          </Text>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </RowFlat>
        <Row>
          <Text fontSize="24px">
            {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol + ' Pool Tokens'}
          </Text>
        </Row>
        <TYPE.italic fontSize={12} textAlign="left" padding={'8px 0 0 0 '} style={{wordBreak: 'break-all'}}>
          {`Output is estimated. If the price changes by more than ${allowedSlippage /
            100}% your transaction will revert.`}
        </TYPE.italic>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }

  const pendingText = `Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_A]?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencies[Field.CURRENCY_B]?.symbol}`

  const { onCurrencySelection } = useAddliquidityActionHandlers()

  const handleCurrencyASelect = useCallback(
    inputCurrency => {
      // setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.CURRENCY_A, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleCurrencyBSelect = useCallback(
    inputCurrency => {
      // setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.CURRENCY_B, inputCurrency)
    },
    [onCurrencySelection]
  )

  // const handleCurrencyASelect = useCallback(
  //   (currencyA: Currency) => {
  //     const newCurrencyIdA = currencyId(currencyA)
  //     if (newCurrencyIdA === currencyIdB) {
  //       history.push(`/add/${currencyIdB}/${currencyIdA}`)
  //     } else {
  //       history.push(`/add/${newCurrencyIdA}/${currencyIdB}`)
  //     }
  //   },
  //   [currencyIdB, history, currencyIdA]
  // )
  // const handleCurrencyBSelect = useCallback(
  //   (currencyB: Currency) => {
  //     const newCurrencyIdB = currencyId(currencyB)
  //     if (currencyIdA === newCurrencyIdB) {
  //       if (currencyIdB) {
  //         history.push(`/add/${currencyIdB}/${newCurrencyIdB}`)
  //       } else {
  //         history.push(`/add/${newCurrencyIdB}`)
  //       }
  //     } else {
  //       // history.push(`/add/${currencyIdA ? currencyIdA : undefined}/${newCurrencyIdB}`)
  //       history.push(`/add/${currencyIdA ? currencyIdA : undefined}/${newCurrencyIdB}`)
  //     }
  //   },
  //   [currencyIdA, history, currencyIdB]
  // )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])

  const isCreate = history.location.pathname.includes('/create')

  const addIsUnsupported = useIsTransactionUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  const showApprovalA = approvalA !== ApprovalState.APPROVED && !!parsedAmounts[Field.CURRENCY_A]
  const showApprovalB = approvalB !== ApprovalState.APPROVED && !!parsedAmounts[Field.CURRENCY_B]

  const addLiquidityFee = useSelector<AppState, AppState['fee']['addLiquitityFeeInTokens']>(state => state.fee.addLiquitityFeeInTokens)
  const addLiquidityFeeFraction = addLiquidityFee === undefined ? undefined : 
    currencyA === undefined ? undefined : new JSBigNumber(addLiquidityFee).div(new JSBigNumber(10).pow(currencyA!.decimals).toString())

  const handleConfirmDismiss = useCallback(() => {
    setErrorMessage('')
  }, [])

  return (
    <>
      <AppBody>
        <AddRemoveTabs creating={isCreate} adding={true} />
        <Wrapper>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash}
            preventedLoss={undefined}
            content={() => (
              errorMessage && errorMessage.length > 0 ? (
                <TransactionErrorContent onDismiss={handleConfirmDismiss} message={errorMessage} />
              ) : (
              <ConfirmationModalContent
                title={noLiquidity ? 'You are creating a pool' : 'You will receive'}
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
              )
            )}
            pendingText={pendingText}
          />
          <AutoColumn gap="20px">
            {noLiquidity ||
              (isCreate ? (
                <ColumnCenter>
                  <BlueCard>
                    <AutoColumn gap="10px">
                      <TYPE.link fontWeight={600} color={'primaryText1'}>
                        You are the first liquidity provider.
                      </TYPE.link>
                      <TYPE.link fontWeight={400} color={'primaryText1'}>
                        The ratio of tokens you add will set the price of this pool.
                      </TYPE.link>
                      <TYPE.link fontWeight={400} color={'primaryText1'}>
                        Once you are happy with the rate click supply to review.
                      </TYPE.link>
                    </AutoColumn>
                  </BlueCard>
                </ColumnCenter>
              ) : (
                <ColumnCenter>
                  <BlueCard>
                    <AutoColumn gap="10px">
                      <TYPE.link fontWeight={400} color={'primaryText1'}>
                        <b>Tip:</b> When you add liquidity, you will receive pool tokens representing your position.
                        These tokens automatically earn fees proportional to your share of the pool, and can be redeemed
                        at any time.
                      </TYPE.link>
                    </AutoColumn>
                  </BlueCard>
                </ColumnCenter>
              ))}
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onFieldAInput}
              onMax={() => {
                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
              }}
              onCurrencySelect={handleCurrencyASelect}
              showMaxButton={false}
              currency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokena"
              showCommonBases={false}
              gasFee={currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID ? addLiquidityFeeFraction : undefined}
            />
            <ColumnCenter>
              <Plus size="16" color={theme.text2} />
            </ColumnCenter>
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onCurrencySelect={handleCurrencyBSelect}
              onMax={() => {
                onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
              }}
              showMaxButton={false}
              currency={currencies[Field.CURRENCY_B]}
              id="add-liquidity-input-tokenb"
              showCommonBases={false}
            />
            {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
              <>
                <LightCard padding="0px" borderRadius={'20px'}>
                  <RowBetween padding="1rem">
                    <TYPE.subHeader fontWeight={500} fontSize={14}>
                      {noLiquidity ? 'Initial prices' : 'Prices'} and pool share
                    </TYPE.subHeader>
                  </RowBetween>{' '}
                  <LightCard padding="1rem" borderRadius={'20px'}>
                    <PoolPriceBar
                      currencies={currencies}
                      poolTokenPercentage={poolTokenPercentage}
                      noLiquidity={noLiquidity}
                      price={price}
                    />
                  </LightCard>
                </LightCard>
                <LightCard  padding="0px" borderRadius={'20px'}>
                  <RowBetween padding="1rem">
                    <TYPE.subHeader fontWeight={500} fontSize={14}>
                      Estimated Gas Fee
                    </TYPE.subHeader>
                  </RowBetween>{' '}
                  <LightCard padding="1rem" borderRadius={'20px'}>
                    <GasFeeBar 
                      currencies={currencies}
                      gasFee={addLiquidityFeeFraction}
                    />
                  </LightCard>
                </LightCard>
              </>
            )}

            {addIsUnsupported ? (
              <ButtonPrimary disabled={true}>
                <TYPE.main mb="4px">Unsupported Asset</TYPE.main>
              </ButtonPrimary>
            ) : !account ? (
              <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
            ) : (              
              <AutoColumn gap={'md'}>
                {(approvalA === ApprovalState.NOT_APPROVED ||
                    approvalA === ApprovalState.PENDING ||
                    approvalB === ApprovalState.NOT_APPROVED ||
                    approvalB === ApprovalState.PENDING) &&
                    isValid && (
                      <RowBetween>
                        {showApprovalA && (
                          <ButtonPrimary
                            borderRadius="12px"
                            padding={'12px'}
                            onClick={approveACallback}
                            disabled={approvalA === ApprovalState.PENDING}
                            width={showApprovalB ? '48%' : '100%'}
                          >
                            {approvalA === ApprovalState.PENDING ? 
                                `Approving ${currencies[Field.CURRENCY_A]?.symbol}` : 
                                `Approve ${currencies[Field.CURRENCY_A]?.symbol}`
                            }
                          </ButtonPrimary>
                        )}
                        {showApprovalB && (
                          <ButtonPrimary
                            borderRadius="12px"
                            padding={'12px'}
                            onClick={approveBCallback}
                            disabled={approvalB === ApprovalState.PENDING}
                            width={showApprovalA ? '48%' : '100%'}
                          >
                            {approvalB === ApprovalState.PENDING ? 
                              `Approving ${currencies[Field.CURRENCY_B]?.symbol}` :
                              `Approve ${currencies[Field.CURRENCY_B]?.symbol}`
                            }
                          </ButtonPrimary>
                        )}
                      </RowBetween>
                    )}
                <ButtonError
                  onClick={() => {
                    expertMode ? onAdd() : setShowConfirm(true)
                  }}
                  disabled={!isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED}
                  error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
                >
                  <Text fontSize={20} fontWeight={500}>
                    {error ?? 'Supply'}
                  </Text>
                </ButtonError>
              </AutoColumn>
            )}
          </AutoColumn>
        </Wrapper>
      </AppBody>
      {!addIsUnsupported ? (
        pair && !noLiquidity && pairState !== PairState.INVALID ? (
          <AutoColumn style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}>
            <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
          </AutoColumn>
        ) : null
      ) : (
        <UnsupportedCurrencyFooter
          show={addIsUnsupported}
          currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
        />
      )}
    </>
  )
}
