import React from 'react'
import styled from 'styled-components'

import { AlertCircle, X } from 'react-feather'
// import { useURLWarningToggle, useURLWarningVisible } from '../../state/user/hooks'
import { isMobile } from 'react-device-detect'
// import { useActiveWeb3React } from '../../hooks'
// import { ChainId } from '@gtoken/sdk'

const PhishAlert = styled.div<{ isActive: any }>`
  width: 100%;
  padding: 6px 6px;
  background-color: #faebd7;
  color: #e6a23c;
  font-size: 13px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  display: inline;
`

export const StyledClose = styled(X)`
  :hover {
    cursor: pointer;
  }
`

export default function URLWarning() {
  // const toggleURLWarning = useURLWarningToggle()
  // const showURLWarning = useURLWarningVisible()

  // const { chainId } = useActiveWeb3React()
  const warningMessage = "Welcome to Conveyor(Developer Preview). Please do not use it for production."

  return isMobile ? (
    <PhishAlert isActive={true}>
        <AlertCircle style={{ marginRight: 6 }} size={12} />
        {warningMessage}
      {/* <StyledClose size={12} onClick={toggleURLWarning} /> */}
    </PhishAlert>
  ) : (
    <PhishAlert isActive={true}>
        <AlertCircle style={{ marginRight: 6 }} size={12} /> 
        {warningMessage}
      {/* <StyledClose size={12} onClick={toggleURLWarning} /> */}
    </PhishAlert>
  )
}
